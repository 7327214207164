export default {
	// This is the list of languages your application supports
	supportedLngs: [
		'de',
	],
	// This is the language you want to use in case
	// if the user language is not in the supportedLngs
	fallbackLng: 'de',
	// Default language in application
	defaultLng: 'de',
	// The default namespace of i18next is "translation", but you can customize it here
	defaultNS: 'route-_root',
	// Disabling suspense is recommended
	react: { useSuspense: false },
}
